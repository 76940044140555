const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const Image = require('nordic/image');
const ClipMultimedia = require('../clip-multimedia/clip-multimedia');

const namespaceIcon = 'clip-picture-icon';

const Figure = props => <ClipMultimedia {...props} />;

const Icon = ({ className }) => (
  <Image className={classnames(className, namespaceIcon)} alt="clip-icon" lazyload="off" src="picture-play.svg" />
);

Icon.propTypes = {
  className: string,
};

Icon.defaultProps = {
  className: '',
};

Figure.propTypes = {};

Figure.defaultProps = {};

module.exports = {
  Figure,
  Icon,
  Preview: Figure,
};
